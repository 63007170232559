<template>
	<div class="warpper" ref="orderview">
		<div class="header">
			<div class="hd-box">
				<div><img src="../../assets/img/logo.png" alt="" /></div>
				<ul>
					<li>
						<a href="#page1" :class="activeNum==1?'active':''" @click="activeButton(1)">首页</a>
					</li>
					<li>
						<a href="#page2" :class="activeNum==2?'active':''" @click="activeButton(2)">角色</a>
					</li>
					<li>
						<a href="#page3" :class="activeNum==3?'active':''" @click="activeButton(3)">游戏截图</a>
					</li>
					<li>
						<a href="#page4" :class="activeNum==4?'active':''" @click="activeButton(4)">世界观</a>
					</li>
					<li>
						<a href="#" @click="toLink('https://payh5.itkls.com')">充值</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="nav-container">
			<div class="nav">
				<a href="#page1" :class="activeNum==1?'active':''" @click="activeButton(1)">TOP</a>
				<a href="#page2" :class="activeNum==2?'active':''" @click="activeButton(2)">角色</a>
				<a href="#page3" :class="activeNum==3?'active':''" @click="activeButton(3)">游戏截图</a>
				<a href="#page4" :class="activeNum==4?'active':''" @click="activeButton(4)">世界观</a>
			</div>
		</div>
		<ul class="page-box">
			<li id="page1" ref="billinfo">
				<span class="page1-bg-1"></span>
				<div class="main1">
					<p class="main1-1"><img src="../../assets/img/page1_img2.png" /></p>
					<div class="main1-2">
						<p>全平台公测正式开启</p>
						<p><img src="../../assets/img/page1_img5.png" /></p>
					</div>
					<div class="main1-3 clearfix">
						<div>
							<p><img src="../../assets/img/page1_img7.png" class="page1_img" @click="toLink('https://dlsk.52wgame.com:8020/download/android/NeoGame_5000_a01_3.5.1.apk')" /></p>
							<p><img src="../../assets/img/page1_img6.png" class="page1_img" @click="toLink('https://apps.apple.com/cn/app/%E6%97%B6%E7%A9%BA%E9%A3%8E%E6%9A%B4-%E5%86%B3%E6%88%98%E6%89%8B%E6%B8%B8/id1536585240')" /></p>
						</div>
						<div><img src="../../assets/img/page1_img8.png" alt="" @click="toLink('http://www.ldmnq.com/ldy/bd6000.html?bd_vid=11392908763007330582')" /></div>
					</div>
					<ul class="main1-4 box-border">
						<li v-for="(item, index) in annoData.slice(0, 5)" :key="index" @click="announcementBttuon()">
							<span>【公告】</span>
							<a href="#" class="box-border">{{item.title}}</a>
							<span>{{item.sDate}}</span>
						</li>
						
					</ul>
				</div>
			</li>
			<li id="page2" ref="cust">
				<!--<span class="page2-bg-2"></span>-->
				<div class="main2">
					<div class="swiper2-box swiper3-box">
						<swiper class="swiper" :options="swiperOption2" ref="swiper2">
							<div class="parallax-bg" slot="parallax-bg" data-swiper-parallax="-23%"></div>
							<swiper-slide v-for="(item, index) in swiper2SwitchData" :key="index">
								<span class="swiper2-bg" :style="{'background-image': 'url('+item.role[item.curIndex].photo+')'}"></span>
								<div class="main2-switch">
									<p><img :src="item.imgurl" /></p>
									<p>{{item.name}}</p>
									<ul>
										<li style="width: 2.5rem;" v-for="(item2, index2) in item.role" :key="index2" :class="item.curIndex == index2 ? 'switch-act' : ''" @click="item.curIndex = index2">
											<p>{{item2.name}}</p>
											<p>{{item2.enName}}</p>
										</li>
									</ul>
								</div>
							</swiper-slide>
							<!--<div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>-->
							<div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
							<div class="swiper-button-next swiper-button-white" slot="button-next"></div>
						</swiper>
					</div>
				</div>
			</li>
			<li id="page3" ref="order">
				<div class="main3">
					<img src="../../assets/img/page3_title.png" alt="" class="page-title" />
					<div class="swiper3-box">
						<div class="swiper-button-prev swiper-button-prev3 swiper-button-white" @click="swiper3ToPrev('swiper3')"></div>
						<div class="swiper-button-next swiper-button-next3 swiper-button-white" @click="swiper3ToNext('swiper3')"></div>
						<swiper class="swiper swiper-3d box-border" :options="swiperOption" ref="swiper3">
							<swiper-slide v-for="(item, index) in bannerList" :key="index">
								<div class="bg-cover" :data-id="item.id">
									<img :src="item.url" alt="" class="box-border" />
									<!--<div class="cover" :style="{ 'background-image': 'url(' + item.advertiseImages + ')' }"></div>-->
								</div>
							</swiper-slide>
							<div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
							<!--<div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>-->
							<!--<div class="swiper-button-next swiper-button-white" slot="button-next"></div>-->
						</swiper>
					</div>
				</div>
			</li>
			<li id="page4" ref="prod">
				<div class="main4">
					<img src="../../assets/img/page4_title.png" alt="" class="page-title" />
					<div class="title-box">
						<p class="title-content">在广阔无际的宇宙中</p>
						<p class="title-content">科技超前的决战勇士文明为了延续文明，开启时空风暴技术把决战勇士的火种传输到宇宙中的各种恒星中</p>
						<p class="margin"></p>
						<p class="title-content">有的文明先行达到恒星中的某个行星发展各自的技术，各种星球酝酿着决战勇士文明的诞生 从目前发现的星系里，太阳系里孕育着文明的火种，决战的勇士们在金星这颗星球上测试发展先前文明留下科技</p>
						<p class="title-content">经过长时间的测试发展，金星上的文明不断发展传播，科技也突飞猛进，各个未知领域的探索也不断尝试</p>
						<p class="margin"></p>
						<p class="title-content">在罗尼特宇宙研究中心，星际飞船的技术虽有进步，但还是处于发展之中，不能将决战勇士进行飞船传输到各个星球。</p>
						<p class="title-content">决战勇士向未知宇宙的探秘未曾停止，</p>
						<p class="title-content">金星的探测器在宇宙中不断探索恒星的情况</p>
						<p class="title-content">斯坦博士在发射的恒星探测器上传来一种信号，在不远处的水星上，又有决战勇士明文的火种在不断发展。</p>
						<p class="title-content">金星决战勇士异常兴奋，加快自己的科技研究，希望早日踏上这颗星球</p>
						
						<p class="margin"></p>
					</div>
				</div>
			</li>
			<li id="page5">
				<div class="footer">
					<div class="main5">
						<div class="footer-top footer-title">
							<p>本网络游戏适合年满18岁（含）以上的玩家使用 开发者:张家口卡洛斯网络技术有限公司 | 版本号：1.1 | 更新时间：2021.1.8</p>
							<p>抵制不良游戏,拒绝盗版游戏。注意自我保护,谨防受骗上当。适度游戏益脑,沉迷游戏伤身。合理安排时间,享受健康生活。</p>
              <p>张家口卡洛斯网络技术有限公司 | COPYRIGHT © 2019-2021 ALL RIGHTS RESERVED</p>
							<br />
							<p>点击加群 - <a style="color: #7bf5e0" target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=zmFb19J1u8BACJb2oxRTIsk0sm6R0YYL&jump_from=webapi">官方1群</a>
               | <a style="color: #7bf5e0" target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=D8MIp05k1z490PLOtEdQyj9nsCZ2QSd5&jump_from=webapi">官方2群</a>
                | <a style="color: #7bf5e0"  target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=QeppcUXJol1DgtMOdIAnS7jkTVqiW78Y&jump_from=webapi">官方3群</a>
              </p>

								
							<p><a href="#">企业QQ：正在开通（9:00-21:00）</a>	</p>									
							<p><a href="#">微信公众号：决战时空风暴</a> </p>
							<p><a href="#">微信小程序：时空助手</a> </p>
														
						</div>
											
<!--						<div class="footer-bottom">							-->
<!--							<div class="footer-margin">-->
<!--                <div class="footer-title">-->
<!--                  <router-link target="_blank" to='/pc/copy?type=0'>公司简介</router-link>-->
<!--                  <span>-</span>-->
<!--                  <router-link target="_blank" to='/pc/copy?type=4'>联系我们</router-link>-->
<!--                  <span>-</span>-->
<!--                  <router-link target="_blank" to='/pc/copy?type=6'>加入我们</router-link>-->
<!--                </div>-->
<!--							</div>-->
<!--							<div class="page-box">	-->
<!--								<p><a href="https://skfbxy.52wgame.com/#/pages/xy/xy">用户协议</a> |-->
<!--                  <router-link target="_blank" to='/pc/copy?type=7'>隐私政策及儿童个人信息保护规则</router-link></p>-->

<!--								<p><a target="_blank" :href="require('../../assets/img/yyzz.jpg')">营业执照</a> ICP备案：冀ICP备19025191号-3 电信增值业务许可证：<a :href="require('../../assets/img/icpba.jpg')"">冀b2-20190755</a></p>-->
<!--								<p> 运营单位：杭州群游科技有限公司 著作权人：张家口卡洛斯网络技术有限公司 出版物号：ISBN 978-7-7979-9582-5 出版单位：杭州群游科技有限公司</p>-->
<!--								<p> 违法及不良信息举报中心 </p>-->
<!--								&lt;!&ndash; <div style="width:300px;margin:0 auto; padding:20px 0;">-->
<!--									<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13073002000090" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">冀公网安备 13073002000090号</p></a>-->
<!--								</div> &ndash;&gt;-->
<!--							</div>-->
<!--						</div>-->
					</div>
				</div>
			</li>
		</ul>
		<!-- 公告 -->
		<div class="make-box" v-show="makeBox">
			<!-- 公告列表 -->
			<transition enter-active-class="animated bounceUp" leave-active-class="animated bounceOut" :duration="200">
				<div class="box-list-box" v-show="detailslist">
					<img src="../../assets/img/page_error.png" alt="" class="page_error" @click="detailslistButton">
					<div class="header-tops border-bottom">
						<img src="../../assets/img/make-logo.png" alt="" class="makeLogo">
					</div>
					<ul class="nactive-box">
						<li class="nactive-lists border-bottom" v-for="(item, index) in annoData.slice(0, 5)" :key="index" @click="detailslistButtons(index)">
							<div class="nactive-content width">
								<img src="../../assets/img/list-mew.png" alt="" class="list_mew"> <span class="anno-title">{{item.title}}</span>
								<div class="time">{{item.date}}</div>
							</div>
						</li>
					</ul>
					<!--<div class="page-ud">
						<div class="up fl">
							<img src="../../assets/img/page-up.png" alt="" class="page-up">上一页
						</div>
						<div class="down fr">
							下一页<img src="../../assets/img/page-down.png" alt="" class="page-down">
						</div>
					</div>-->
				</div>
			</transition>
			<!-- 公告詳情 -->
			<div class="box-list-box" v-show="details">
				<div class="header-top border-bottom">
					<p class="title-make">{{annoData[annoIndex].title}}</p>
					<p class="title-time">{{annoData[annoIndex].date}}</p>
					<img src="../../assets/img/page_error.png" alt="" class="page_errors" @click="goBack">
					<div class="go-back border-bottom" @click="goBack">
						返回列表
					</div>
				</div>
				<ul class="nactive-box">
					<li class="nactive-list" v-html="annoData[annoIndex].content"></li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
	import 'swiper/css/swiper.css';
	export default {
		name: 'Home',
		title: 'Parallax',
		components: {
			Swiper,
			SwiperSlide
		},
		data() {
			return {
				details: false,
				detailslist: false,
				makeBox: false,
				// 第一屏幕数据配置
				annoIndex: 0,
				annoData: [{
          title: '海外版即将开发测试',
          date: '2021-6-13 08:00',
          sDate: '1/01',
          content: '海外决战勇士期待已久的新服即将开启，时空决战手游官方运作人员经过不断的优化测试， \
									针对海外的版本敬请关注。'
        },{
					title: '新区水星开区时间',
					date: '2021-1-1 08:00',
					sDate: '1/01',
					content: '决战勇士期待已久的新服即将开启，时空风暴-决战手游官方运作人员经过不断的优化测试， \
									带来令人愉悦的体验，在这个世界中，找回你曾经的记忆，这里将是你们的狂欢盛宴，\
									现在正式宣布，开启时间：2021年1月18日中午12点注册一次游戏账号，任何区都可以登陆，\
									老玩家无需注册新账号。新服将有大量活动等待决战勇士参与，游戏内关注。'
				}, {
					title: '官方qq号及公众号',
					date: '2021-1-1 08:00',
					sDate: '1/01',
					content: '目前官方qq号只有3个 分别为: \
					官方1群9634014；官方2群116946091；官方3群902634557；\
					其他大量的决战手游群为其他玩家自行建立，其他群发布的信息玩家时刻注意，谨防被骗！\
					时空助手：打开微信，搜索时空助手，或则直接扫描二维码，就可以关注微信小程序\
					时空助手功能：找回密码，绑定手机，修改密码等功能'
				}, {
					title: '游戏介绍',
					date: '2021-1-1 08:00',
					sDate: '1/01',
					content: '本游戏为正版独家授权，国产开发的游戏，公正公平。'
				}, {
					title: '人气火爆，时刻注意防骗',
					date: '2021-1-1 08:00',
					sDate: '1/01',
					content: '官方为维护游戏环境公平公正，让玩家玩的有保障，不会有任何让你交钱，给予特殊好处等做法，\
					一切内容都以官方公告为准，不会有任何突破公告的内容，包括充值等，千万别贪图便宜，相信骗子。\
					防骗注意事项：\
					1.冒充官方管理员，会跟管理员头像一样，以招内部人员，推广人员等名义，给你特别好处，让你交保证金。\
					2.冒充官方人员，让你支持游戏，给给予你消耗品，让你活跃游戏，说的让你真以为是官方人员，让你保密，让你交钱。\
					3.qq上发个装备图片，让你交钱，这种骗子较多。 \
					4.骗子号，跟买方和卖方同时聊交易的东西，然后，游戏里让你们两见面，当你钱付给骗子的时候，让你跟卖方引起冲突，\
					游戏里不仅要确认身份，还要确认对方跟你聊的qq号，防止被骗。\
					5.其他容易被骗的内容，多加注意。'
				}],
				// 第二屏幕数据配置
				swiper2SwitchData: [{
					name: '格斗家',
					curIndex: 0,
					imgurl:require('../../assets/img/main2-switch-1.png'),
					role: [{
						name: '皮肤1',
						enName: '时装效果',
						photo: require('../../assets/img/swiper2-bg1.png')
					},{
						name: '皮肤2',
						enName: '时装效果',
						photo: require('../../assets/img/swiper2-bg1.png')
					}]
				}, {
					name: '剑武士',
					curIndex: 0,
					imgurl:require('../../assets/img/main2-switch-2.png'),
					role: [{
						name: '皮肤1',
						enName: '时装效果',
						photo: require('../../assets/img/swiper2-bg2.png')
					},{
						name: '皮肤2',
						enName: '时装效果',
						photo: require('../../assets/img/swiper2-bg2.png')
					}]
				}, {
					name: '魔法师',
					curIndex: 0,
					imgurl:require('../../assets/img/main2-switch-3.png'),
					role: [{
						name: '皮肤1',
						enName: '时装效果',
						photo: require('../../assets/img/swiper2-bg3.png')
					},{
						name: '皮肤2',
						enName: '时装效果',
						photo: require('../../assets/img/swiper2-bg3.png')
					}]
				}, {
					name: '枪械师',
					curIndex: 0,
					imgurl:require('../../assets/img/main2-switch-4.png'),
					role: [{
						name: '皮肤1',
						enName: '时装效果',
						photo: require('../../assets/img/swiper2-bg4.png')
					},{
						name: '皮肤2',
						enName: '时装效果',
						photo: require('../../assets/img/swiper2-bg4.png')
					}]
				}],
				activeNum: 1,
				// 第三屏幕数据配置
				bannerList: [{
					// url: require('../../assets/img/screenshot-1.jpg')
					url:'https://gitee.com/krospath/imgs/raw/master/screenshot-1.jpg'
				}, {
					// url: require('../../assets/img/screenshot-2.jpg')
					url:'https://gitee.com/krospath/imgs/raw/master/screenshot-2.jpg'
				}, {
					// url: require('../../assets/img/screenshot-3.jpg')
					url:'https://gitee.com/krospath/imgs/raw/master/screenshot-3.jpg'
				}],
				swiperOption2: {
					speed: 600,
					parallax: true,
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					}
				},
				swiperOption: {
					slidesPerView: 'auto',
					loop: true,
					centeredSlides: true,
					watchSlidesProgress: true,
					// spaceBetween: 0,
					effect: 'coverflow',
					coverflowEffect: {
						rotate: 0,
						stretch: 480,
						depth: 390,
						modifier: 1,
						slideShadows: false
					},
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
					navigation: {
						nextEl: '.swiper-button-next3',
						prevEl: '.swiper-button-prev4'
					},
					on: {
						setTranslate: function() {
							let slides = this.slides
							for(let i = 0; i < slides.length; i++) {
								let slide = slides.eq(i)
								let progress = slides[i].progress
								slide[0].style.opacity = (1 - Math.abs(progress) / 6)
								slide[0].style.transform = 'scale(' + (1 - Math.abs(progress) / 8) + ')'
							}
						},
						setTransition: function(transition) {
							let slides = this.slides
							for(let i = 0; i < slides.length; i++) {
								let slide = slides.eq(i)
								slide[0].style.transform = transition
							}
						}
					}
				},
				//					on: {
				//						//关键在这儿，通过点击事件拿到对应的元素，从而确定具体index
				//						tap: function(e) {
				//							console.log(e, e.target, 'click');
				//							let dom = e.target.parentNode;
				//							// console.log(dom.dataset.id);
				//							vm.handle(dom.dataset.id);
				//
				//						}
				//					}
				// },
			};
		},
		directives: {
			swiper: directive
		},
		created() {
			var activeNumUrl = this.getUrlKey("activeNum")
			if(activeNumUrl){
			   console.log("activeNumUrl",activeNumUrl)
			   this.activeNum = activeNumUrl
			   // this.goNavList(this.activeNum)
			}
		},
		mounted() {
			
			setTimeout(function() {
				this.aa = true;
			}.bind(this), 3000)

			//			 console.log(window);
			//			 window.addEventListener('scroll', function() {
			//			 	var scrollTop = document.documentElement.scrollTop;
			//			 	console.log(scrollTop);
			//			 	console.log(document.getElementById('page1').offsetTop);
			//			 	if (document.body.scrollHeight <= window.screen.height + document.body.scrollTop) {
			//			 		//				if(that.noMore) {
			//			 		//					return
			//			 		//				}
			//			 		//				if(that.initOver) {
			//			 		//					that.initList()
			//			 		//				}
			//			 	}
			//			 });
		},
		methods: {
			// goNavList(val) {
			//     let height = 0;
			//     switch(val) {
			//         case 1:
			//             height = 0;
			//             break;
			//         case 2:
			//             height = this.$refs.billinfo.offsetHeight;
			//             break;
			//         case 3:
			//             height = this.$refs.billinfo.offsetHeight + this.$refs.cust.offsetHeight;
			//             break;
			//         case 4:
			//             height = this.$refs.billinfo.offsetHeight + this.$refs.cust.offsetHeight + this.$refs.order.offsetHeight;
			//             break;
			//         default:
			//             break;
			//     }
			// 	console.log("this.$refs.orderview",this.$refs.orderview,this.$refs.billinfo.offsetHeight + this.$refs.cust.offsetHeight + this.$refs.order.offsetHeight)
			//     this.$refs.orderview.scrollTop = height;
			// },
			getUrlKey(name){//获取url 参数
			    return decodeURIComponent(
			        (new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[,""])[1].replace(/\+/g,'%20'))||null;
			},
			swiper3ToNext(swiper) {},
			swiper3ToPrev(swiper) {},
			detailslistButtons(index) {
				this.makeBox = true;
				this.detailslist = false
				this.details = true;
				this.annoIndex = index;
			},
			detailslistButton() {
				this.makeBox = false;
				this.detailslist = false
			},
			announcementBttuon() {
				this.makeBox = true;
				setTimeout(function() {
					this.detailslist = true;
				}.bind(this), 300);
			},
			goBack() {
				this.makeBox = true;
				this.detailslist = true;
				this.details = false;
			},
			activeButton(id) {
				this.activeNum = id
			},
			toLink(url) {
				window.open(url);
			}
		}
	};
</script>
<style scoped>
	/* 公告模块 */
	/* 公告列表 */
	
 .width {
		width: 100%;
		height: 100%;
	}
	
	.page-ud {
		width: 4rem;
		margin: 0 auto;
		height: auto;
		overflow: hidden;
		font-weight: 400;
		color: #ffffff;
		font-size: 0.3rem;
		padding-top: .5rem;
	}
	
	.nactive-lists {
		position: relative;
		font-weight: 400;
		text-align: left;
		color: #ffffff;
		cursor: pointer;
		font-size: 0.3rem;
		letter-spacing: 0px;
		padding: 0.5rem 4rem 0.5rem 0.5rem;
		padding-bottom: 0.5rem !important;
	}
	
	.nactive-list {
		position: relative;
		padding-top: 0.2rem;
		font-weight: 400;
		text-align: left;
		color: #ffffff;
		font-size: 0.3rem;
		line-height: 30px;
		letter-spacing: 0px;
	}
	
	.page-ud img {
		width: 0.25rem;
	}
	
	.page_error {
		width: 1rem;
		position: absolute;
		right: .3rem;
		cursor: pointer;
	}
	
	.page_errors {
		width: 1rem;
		position: absolute;
		right: .3rem;
		top: .5rem;
		cursor: pointer;
	}
	
	.fl {
		float: left;
	}
	
	.fr {
		float: right;
	}
	
	.nactive-content .list_mew {
		width: 0.25rem;
		position: absolute;
		top: 0.6rem;
		left: 0
	}
	
	.time {
		position: absolute;
		top: 0.5rem;
		right: 0
	}
	
	.border-bottom {
		padding-bottom: .4rem;
		border-bottom: 1px solid rgba(255, 255, 255, .2);
	}
	
	.makeLogo {
		width: 2.5rem;
	}
	
	.swiper3-box {
		position: relative;
		width: 100%;
		height: auto;
	}
	
	.make-box {
		position: fixed;
		right: 0;
		left: 0;
		top: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, .7);
		z-index: 20;
	}
	
	.box-list-box {
		width: 70%;
		height: auto;
		overflow: hidden;
		position: absolute;
		top: 50%;
		left: 50%;
		padding: 0.5rem 1.2rem;
		background-color: rgba(0, 0, 0, .9);
		box-shadow: 0 0 5px rgba(255, 255, 255, .3);
		transform: translate(-50%, -50%);
	}
	/* 公告详情 */
	
	.title-time {
		font-size: 16px;
		font-weight: 400;
		text-align: center;
		color: #ffffff;
		padding: .2rem 0;
	}
	
	.title-make {
		font-size: 24px;
		font-weight: 600;
		text-align: center;
		color: #ffffff;
	}
	
	.header-top {
		width: 100%;
		height: 1.5rem;
	}
	
	.go-back {
		width: 2.5rem;
		cursor: pointer;
		height: .6rem;
		background: url(../../assets/img/go-back.png) no-repeat center;
		background-size: 100% 100%;
		position: absolute;
		left: .3rem;
		top: .5rem;
		font-size: .3rem;
		font-weight: 400;
		text-align: center;
		color: #ffffff;
		line-height: 1rem;
	}
	/* 结束end */
	
	.nav-container {
		position: fixed;
		font-size: .16rem;
		right: .6rem;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		height: 4.6rem;
		border-right: 1px solid rgba(170, 170, 170, .4);
		z-index: 2;
	}
	
	.nav-container .nav {
		position: relative;
		top: 38%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	
	.nav-container .nav>a {
		display: block;
		position: relative;
		color: #fff;
		text-align: right;
		padding-right: 0.5rem;
		margin: 0.5rem 0;
		text-decoration: underline;
	}
	
	.nav-container .nav>a:after {
		content: "";
		display: block;
		position: absolute;
		background-image: url(../../assets/img/nav.png);
		width: 0.3rem;
		height: 0.3rem;
		left: 100%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	
	.nav-container .nav>a.active {
		color: #7bf5e0;
	}
	
	.nav-container .nav>a:after {
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
	
	.nav-container .nav>a.active:after {
		background-image: url(../../assets/img/nav_sve.png);
		width: .37rem;
		height: .37rem;
	}
	
	.swiper {
		width: 100%;
		height: 380px;
	}
	
	/deep/.swiper-slide {
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 200px!important;
		box-sizing: border-box;
		padding: 0 80px;
		background-color: transparent;
	}
	
	.title {
		margin-bottom: $lg-gap;
		font-size: $font-size-huge * 2;
		font-weight: bold;
	}
	
	.subtitle {
		margin-bottom: $gap;
		font-size: $font-size-base * 2;
	}
	
	.text {
		max-width: 430px;
		line-height: 1.32;
	}
	
	.warpper {
		width: 100%;
		height: auto;
		overflow: auto;
		background: #010714;
	}
	/* 头部 */
	
	.header {
		width: 100%;
		height: 60px;
		line-height: 60px;
		background: #fff;
	}
	
	.hd-box {
		width: 80%;
		/*max-width: 1200px;*/
		margin: 0 auto;
	}
	
	.hd-box>div {
		float: left;
	}
	
	.hd-box>div img {
		height: 36px;
		margin-top: 15px;
		float: left;
	}
	
	.hd-box>ul {
		width: auto;
		height: 100%;
		float: right;
	}
	
	.hd-box>ul li {
		width: auto;
		height: 100%;
		float: left;
	}
	
	.hd-box>ul li a {
		display: block;
		width: auto;
		height: 100%;
		margin: 0 20px;
		color: #333;
		font-size: 14px;
		transition: all 0.15s linear;
	}
	
	.hd-box>ul li a:hover {
		color: #57bdf5;
	}
	/* 页面 */
	
	.page-box {
		width: 100%;
		height: auto;
		overflow: auto;
	}
	
	.page-box>li {
		position: relative;
		display: inline-block;
		width: 100%;
		height: auto;
		color: #fff;
		float: left;
	}
	
	.page-box>li>div {
		position: relative;
		width: 80%;
		height: auto;
		margin: 0 auto;
		color: #fff;
		z-index: 2;
	}
	
	.page-box>li:nth-child(1) {
		width: 100%;
		min-height: 15rem;
		/* background: url(../../assets/img/page1_cover.jpg) no-repeat center; */
		background: url(https://gitee.com/krospath/imgs/raw/master/page1_cover.jpg) no-repeat center;
		background-size: cover;
	}
	
	.page-box>li:nth-child(2) {
		width: 100%;
		min-height: 15rem;
		/* background: url(../../assets/img/page2_cover.jpg) no-repeat center; */
		background: url(https://gitee.com/krospath/imgs/raw/master/page2_cover.jpg) no-repeat center;
		background-size: cover;
	}
	
	.page-box>li:nth-child(3) {
		width: 100%;
		min-height: 15rem;
		/* background: url(../../assets/img/page3_cover.jpg) no-repeat center; */
		background: url(https://gitee.com/krospath/imgs/raw/master/page3_cover.jpg) no-repeat center;
		background-size: cover;
	}
	
	.page-box>li:nth-child(4) {
		width: 100%;
		min-height: 15rem;
		/* background: url(../../assets/img/page4_cover.jpg) no-repeat center; */
		background: url(https://gitee.com/krospath/imgs/raw/master/page4_cover.jpg) no-repeat center;
		background-size: cover;
	}
	
	.page-box>li:nth-child(5) {
		width: 100%;
		min-height: 9rem;
		/* background: url(../../assets/img/page5_cover.jpg) no-repeat center; */
		background: url(https://gitee.com/krospath/imgs/raw/master/page5_cover.jpg) no-repeat center;
		background-size: cover;
	}
	
	.page1-bg-1 {
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: url(../../assets/img/page1_img1.png) no-repeat right;
		background-size: auto 100%;
	}
	
	.page2-bg-2 {
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: url(../../assets/img/page2_game_user.png) no-repeat right;
		background-size: auto 100%;
	}
	
	.main1>*,
	.main2>*,
	.main3>*,
	.main4>* {
		margin-bottom: 0.1rem;
	}
	
	.main1 {
		margin-top: 1rem!important;
	}
	
	.main1-1 img,
	.main2-1 img {
		height: 2rem;
	}
	
	.main2 {
		min-height: 15rem;
	}
	
	/deep/.main2 .swiper3-box,
	/deep/.main2 .swiper-container,
	/deep/.main2 .swiper-wrapper,
	/deep/.main2 .swiper-slide {
		position: relative;
		width: 100%;
		height: auto;
		min-height: 15rem;
	}
	
	.swiper2-bg {
		position: absolute;
		bottom: 0;
		right: 0;
		display: inline-block;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: right bottom;
		background-size: auto 95%;
	}
	
	.main2-switch {
		position: absolute;
		top: 2rem;
		width: 9rem;
		height: auto;
		text-align: center;
		z-index: 100;
	}
	
	.swiper2-box .swiper-button-white {
		width: 1.3rem;
		height: 1.3rem;
		transition: all .15s linear;
		z-index: 110;
	}
	
	.swiper2-box .swiper-button-white:after {
		content: '';
	}
	
	.swiper2-box .swiper-button-prev {
		background: url(../../assets/img/navJt-l.png) no-repeat;
		background-size: 1.3rem 1.3rem;
	}
	
	.swiper2-box .swiper-button-prev:hover {
		background: url(../../assets/img/bannerH5-l.png) no-repeat;
		background-size: 1.3rem 1.3rem;
	}
	
	.swiper2-box .swiper-button-next {
		background: url(../../assets/img/navJt-r.png) no-repeat;
		background-size: 1.3rem 1.3rem;
	}
	
	.swiper2-box .swiper-button-next:hover {
		background: url(../../assets/img/bannerH5-r.png) no-repeat;
		background-size: 1.3rem 1.3rem;
	}
	
	.swiper2-box .swiper-button-prev {
		position: absolute;
		top: 3.5rem;
		left: calc(0.8rem + 80px);
		left: -webkit-calc(0.8rem + 80px);
		left: -moz-calc(0.8rem + 80px);
		left: -ms-calc(0.8rem + 80px);
	}
	
	.swiper2-box .swiper-button-next {
		position: absolute;
		top: 3.5rem;
		left: calc(7rem + 80px);
		left: -webkit-calc(7rem + 80px);
		left: -moz-calc(7rem + 80px);
		left: -ms-calc(7rem + 80px);
	}
	
	.main2-switch p:nth-child(1) img {
		height: 3rem;
	}
	
	.main2-switch>p:nth-child(2) {
		margin: 0.2rem 0 0.5rem;
		font-size: 0.6rem;
		font-family: myFirstFont;
	}
	
	.main2-switch ul li {
		display: inline-block;
		width: auto;
		height: auto;
		padding: 0.1rem 1.5rem;
		border: 1px dashed #fff;
		background: rgba(66, 120, 146, .3);
		color: #fff;
		margin-bottom: 0.3rem;
		cursor: pointer;
	}
	
	.main2-switch ul li.switch-act {
		background: url(../../assets/img/switch_ac_bg.png) repeat;
		background-size: 20px 60px;
	}
	
	.main2-switch ul li p:nth-child(1) {
		font-family: myFirstFont;
		
		font-size: 0.6rem;
		line-height: 1;
		margin: 0.1rem 0;
	}
	
	.main2-switch ul li p:nth-child(2) {
		font-size: 0.3rem;
		line-height: 1;
		margin: 0.1rem 0;
	}
	
	.main1-2 {
		width: 9rem;
		height: 3rem;
		background: url(../../assets/img/page1_img3.png) no-repeat;
		background-size: 9rem 3rem;
	}
	
	.main1-2 p:nth-child(1) {
		display: inline-block;
		color: #12fff1;
		font-size: 0.6rem;
		font-weight: 550;
		font-style: italic;
		font-family: myFirstFont;
		margin-top: 0.8rem;
	}
	
	.main1-2 p {
		float: left;
	}
	
	.main1-2 p:nth-child(2) img {
		height: 0.8rem;
	}
	
	.main1-3 {
		width: 9rem;
		height: auto;
	}
	
	.main1-3>div {
		float: left;
	}
	
	.main1-3>div img {
		width: 100%;
		margin-bottom: 0.2rem;
		float: left;
	}
	
	.main1-3>div:nth-child(1) {
		width: 56%;
	}
	
	.main1-3>div:nth-child(2) {
		width: 43%;
		float: right;
	}
	
	.main1-4 {
		width: 9rem;
		height: auto;
		padding: 10px;
		background: url(../../assets/img/page1_img10.png) no-repeat;
		background-size: 9rem 100%;
		color: #fff;
		font-size: 14px;
	}
	
	.main1-4 li {
		position: relative;
		height: auto;
		line-height: 1;
		margin: 5px 0;
	}
	
	.main1 li>* {
		display: inline-block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	
	.main1 li span:nth-child(1) {
		position: absolute;
		top: 0;
		left: 0;
		width: 60px;
		color: #ffc731;
	}
	
	.main1 li a:nth-child(2) {
		width: 100%;
		padding: 0 40px 0 60px;
		color: #fff;
		font-weight: 500;
		transition: all .1s linear;
	}
	
	.main1 li a:nth-child(2):hover,
	.anno-title:hover {
		color: #57bdf5;
		text-decoration: underline;
	}
	
	.main1 li span:nth-child(3) {
		position: absolute;
		top: 0;
		right: 0;
		width: 40px;
		text-align: right;
		font-size: 14px;
	}
	
	.swiper-container {
		width: 100%;
		/*height: 100%!important;*/
		height: auto !important;
		margin: 0 auto;
		position: relative;
	}
	
	.swiper-slide {
		width: 100% !important;
		height: auto !important;
		min-height: 400px !important;
		/*transform: scale(1);*/
		transition-timing-function: ease;
		transition-duration: 300ms;
		/*display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;*/
		position: relative;
		/*padding: 0.14rem 0 0.11rem;*/
	}
	
	.swiper-slide:nth-child(2) {
		min-height: 500px !important;
	}
	
	.swiper-3d {
		width: 80%;
		height: 100%;
		/*padding: 0 1rem;*/
	}
	
	/deep/.swiper-3d .swiper-button-next {
		width: 2rem;
		height: 2rem;
		background: url(../../assets/img/navJt-r.png) no-repeat;
		background-size: 2rem 2rem;
	}
	
	/deep/.swiper-3d .swiper-pagination .swiper-pagination-bullet {
		width: 10px;
		height: 10px;
		background: transparent;
		border: 1px solid #fff;
		border-radius: 0;
		opacity: 1;
	}
	
	/deep/.swiper-3d .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
		background: #0066FF;
		border-color: #0066FF;
		opacity: 1;
	}
	
	.swiper3-box>.swiper-button-white:after {
		content: '';
	}
	
	.swiper3-box>.swiper-button-white {
		width: 1.3rem;
		height: 1.3rem;
		transition: all .15s linear;
		z-index: 110;
	}
	
	.swiper3-box .swiper-button-prev {
		background: url(../../assets/img/navJt-l.png) no-repeat;
		background-size: 1.3rem 1.3rem;
	}
	
	.swiper3-box .swiper-button-prev:hover {
		background: url(../../assets/img/bannerH5-l.png) no-repeat;
		background-size: 1.3rem 1.3rem;
	}
	
	.swiper3-box .swiper-button-next {
		background: url(../../assets/img/navJt-r.png) no-repeat;
		background-size: 1.3rem 1.3rem;
	}
	
	.swiper3-box .swiper-button-next:hover {
		background: url(../../assets/img/bannerH5-r.png) no-repeat;
		background-size: 1.3rem 1.3rem;
	}
	
	.bg-cover {
		width: 100%;
		height: auto !important;
		/*min-height: 100% !important;*/
		font-size: 0.12rem;
		font-weight: bold;
		color: #366f5e;
		line-height: 0.18rem;
		/* background: #000;*/
		/*.point-text {
			color: red;
		}*/
	}
	
	.bg-cover img {
		width: 100%;
		border: 1px solid #fff;
	}
	
	.bg-cover .cover {
		width: 100%;
		height: 6rem;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100% 6rem;
	}
	
	.slider-item.slider-active {
		transform: scale(1);
		z-index: 999;
	}
	
	.slider-item.slider-active-copy {
		transform: scale(1);
		z-index: 999;
	}
	
	.card-details-swiper .swiper-container {
		height: 100% !important;
	}
	
	.main2 .left_img {
		width: 12rem;
	}
	
	.page-title {
		display: block;
		padding: 1rem 0.5rem;
		width: 80%;
		margin: 0 auto;
	}
	
	.title-box {
		width: 100%;
		margin: 0 auto;
	}
	
	.margin {
		height: 1rem;
	}
	
	.title-box .title-content {
		text-align: center;
		font-weight: 550;
		text-align: center;
		color: #e3e7e3;
		font-size: 0.3rem;
		line-height: 0.6rem;
	}
	
	.footer {
		padding: 20px 0;
		width: 100% !important;
		opacity: 0.8;
		background: #000000;
	}
	
	.footer .main5 {
		position: relative;
		width: 80%;
		height: auto;
		margin: 0 auto;
		color: #fff;
		z-index: 2;
	}
	
	.footer_logo {
		display: inline-block;
		width: 60px;
		margin-right: 30px;
		vertical-align: middle;
	}
	
	.footer-top {
		height: auto;
		overflow: hidden;
		margin: 0 auto;
		width: 100%;
		text-align: center;
	}
	
	.footer-margin {
		font-size: 0;
	}
	
	.footer-title {
		display: inline-block;
		font-size: 13px;
		font-weight: 550;
		color: #fff;
		padding-bottom: 10px;
	}
	
	.footer-title a {
		color: #fff;
		transition: all 0.1s linear;
	}
	
	.footer-title a:hover {
		color: #57bdf5;
	}
	
	.footer-title span {
		margin: 0 10px;
	}
	
	.footer-bottom {
		text-align: center;
		font-size: 12px;
		font-weight: 400;
		padding-top: 10px;
		color: #ffffff;
	}
	
	.footer-bottom p:first-child {
		padding-bottom: 10px;
	}
	.footer-bottom a{
		color: #ffffff;
	}
	
	.footer-margin {
		margin: 0 auto;
	}
</style>